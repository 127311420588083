import './ChatBar.css';
import ButtomUserBar from '../ButtomUserBar/ButtomUserBar';
import axios from 'axios';


function textToSpeech(texto) {
    //const textoParaFala = document.getElementById('prompt').value;
    const textoParaFala = texto;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({message: texto})
    };
  
    fetch('https://api-plataforma-integradora-364774706200.us-central1.run.app/tts', requestOptions)
      .then(response => {
        if (response.ok) {
          return response.arrayBuffer();
        } else {
          throw new Error(`Falha na requisição: ${response.status} - ${response.statusText}`);
        }
      })
      .then(data => {
        const blob = new Blob([data], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(blob);
  
        const audioElement = new Audio(audioUrl);
        audioElement.play();
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }

function ChatBar() {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            sendMessage(event.target.value);
            event.target.value = '';
        }
    };

    const sendMessage = async (message) => {
        try {
            const response = await axios.post('https://api-plataforma-integradora-364774706200.us-central1.run.app/chat', {
                message: message
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Resposta da API:', response.data.response);
            textToSpeech(response.data.response);


        } catch (error) {
            console.error('Erro ao enviar mensagem:', error);
        }
    };
    return (
        <div className='container'>
            <ButtomUserBar icon="mic" />
            <div className='div-txt-input'>
                <input type="text" className='txt-input' placeholder='Pergunte par a NAI' onKeyDown={handleKeyPress} />
            </div>
            <div className='btn-group'>
                <ButtomUserBar icon="attach_file" />
                <div className='btn-history'>
                    <span className="material-symbols-outlined icon-history">dock_to_bottom</span>
                </div>
            </div>
        </div>
    )
}

export default ChatBar;

