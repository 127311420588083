import React, { useState } from 'react';
import './UserBar.css'
import ButtomUserBar from '../ButtomUserBar/ButtomUserBar';
import { useAuthContext } from "@asgardeo/auth-react";


function UserBar({ theme, toggleTheme, isOpen }) {

    const { state, signIn, signOut } = useAuthContext();
    
    const handleThemeToggle = () => {
        toggleTheme();
    };

    const handleAccountClick = () => {
        window.location.href = 'https://identidade.senai.br/minha-conta';
    };

    const handleLogout = () => {
        window.location.href = 'https://identidade.senai.br/minha-conta';
    };

    return (
        <div className={`UserBar ${theme} ${isOpen ? 'open' : 'closed'}`}>
            <ButtomUserBar
                icon={theme === 'dark' ? 'light_mode' : 'dark_mode'}
                onClick={handleThemeToggle}
            />
            <ButtomUserBar
                icon="account_circle"
                onClick={handleAccountClick}
            />
            <ButtomUserBar
                icon="logout"
                onClick={ () => signOut() }
            />
        </div>
    )
}

export default UserBar;