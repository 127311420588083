import './ButtomUserBar.css'

function ButtomUserBar({ icon, onClick, link }) {
    return (
        <button
            onClick={onClick}
            className='btn-user-bar'
            type="button"
        >
            <span className="material-symbols-outlined icon-user-bar">{icon}</span>
        </button>
    )
}

export default ButtomUserBar;