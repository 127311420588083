import './Header.css';
import UserBar from '../UserBar/UserBar';
import { useState } from 'react';
import TextButtom from '../TextButtom/TextButtom';
import { useAuthContext } from "@asgardeo/auth-react";


function Header({ theme, toggleTheme, showChatBar }) {
    const [isUserBarOpen, setIsUserBarOpen] = useState(false);
    const { state, signIn, signOut } = useAuthContext();
    const toggleUserBar = () => {
        setIsUserBarOpen(!isUserBarOpen);
    };

    const handleAccountClick = () => {
        window.location.href = 'https://identidade.senai.br/cadastro';
    };

    return (
        <div className="header">
            <img className='logo' alt="Logo Senai Nacional" />
            <div className='div-user-bar'>
                {!showChatBar ? (
                    <div className='buttomsLogin'>
                        <TextButtom
                            texto="Login"
                            buttonColor="var(--cor-de-fundo-icon-bottom-bar-default)"
                            textColor="var(--cor-icon-bottom-bar-default)"
                            onClick={ () => signIn() }
                        />
                        <TextButtom
                            texto="Cadastrar"
                            buttonColor="var(--Brand-Orange-500)"
                            textColor="var(--Neutral-Neutral-0)"
                            onClick={handleAccountClick}
                        />
                    </div>
                ) : (
                    <div className='avatar' onClick={toggleUserBar}>
                        <img src="/img/avatar.svg" alt="Avatar" />
                    </div>
                )}
                <UserBar theme={theme} toggleTheme={toggleTheme} isOpen={isUserBarOpen} />
            </div>
        </div>
    )
}

export default Header;
