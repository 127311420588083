import './TextButtom.css'

function TextButtom({ texto, buttonColor, textColor, link, onClick }) {
    return (
        <button
            onClick={onClick}
            className="text-buttom"
            style={{ backgroundColor: buttonColor }}
            type="button"
        >
            <span className='textoBotao' style={{ color: textColor }}>{texto}</span>
        </button>
    )
}

export default TextButtom;