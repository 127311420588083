import './WidgetCard.css';


function WidgetCard({ text, icon, link }) {
    const handleClick = () => {
        window.location.href = link;
    };

    return (
        <div className='card' onClick={handleClick}>
            <div>
                <span className="material-symbols-outlined gradient-icon">{icon}</span>
            </div>
            <div className='textCard'>
                <p>{text}</p>
            </div>
        </div>
    )
}

export default WidgetCard;